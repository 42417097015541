<template>
    <div class="file-container">
        <div v-if="docLink == null">
            <div class="upload-btn-wrapper" v-if="!isAttachment && !isUploading">
                <button class="btn">Upload</button>
                <input type="file" @change="chooseLogo" class="uploadButton" :accept="[fileTypeAccept]" />
            </div>

            <ProgressBar
                class="upload-btn-wrapper"
                :class="{ 'upload-disabled': uploadDisabled }"
                v-if="!isAttachment && isUploading"
                mode="indeterminate"
                style="height: 0.5rem; width: 7rem; margin-left: 1.5rem"
            />

            <div
                class="upload-btn-wrapper"
                style="margin-top: 0.5rem; position: relative; left: 21rem"
                v-if="isAttachment && !isUploading"
            >
                <h6 class="attach-file" :class="{ 'upload-disabled': uploadDisabled }">
                    <i class="pi pi-paperclip"></i>&nbsp; Attach file
                </h6>
                <input
                    type="file"
                    @change="chooseLogo"
                    class="uploadButton"
                    :accept="[fileTypeAccept]"
                    :disabled="uploadDisabled"
                />
            </div>

            <ProgressBar
                class="upload-btn-wrapper"
                v-if="isAttachment && isUploading"
                mode="indeterminate"
                style="height: 0.5rem; width: 7rem; margin-left: 1rem; position: relative; left: 21rem"
            />
        </div>

        <span v-bind:class="!isAttachment ? 'upload-link' : 'attach-link'" v-if="docLink !== null || undefined">
            <img v-if="fieldName === 'company_logo'" class="image-logo" :src="docLink" />
            <a v-else :href="docLink" target="_blank">{{ fileName }}</a>
            <i class="pi pi-times cross" @click="deleteFileDialog = true"></i>
        </span>
    </div>
    <Dialog v-model:visible="deleteFileDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.75rem; margin-right: 0.5rem" />
            <span v-if="fileName"
                >Are you sure you want to delete <b>{{ fileName }}</b> ?</span
            >
        </div>

        <template #footer>
            <Button label="No" class="p-button p-component p-button-outlined button" @click="deleteFileDialog = false" />
            <Button label="Yes" class="p-button p-component button" @click="cancel" />
        </template>
    </Dialog>
</template>

<script>
import axios from 'axios';
import getAxios from '../../service/common/header';
import CurrentUserService from '../../service/CurrentUserService';

const currentUser = new CurrentUserService();
const ifImage = (fileExt) => {
    const allowedExts = new Set(['jpg', 'jpeg', 'webp', 'png']);
    return allowedExts.has(fileExt.toLowerCase());
};
const ifDoc = (fileExt) => {
    const allowedExts = new Set(['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'webp', 'png', 'xlsx', 'xls', 'ppt', 'pptx']);
    return allowedExts.has(fileExt.toLowerCase());
};

export default {
    props: ['isImage', 'isAttachment', 'id', 'fieldName', 'fieldId', 'logoUrl'],
    computed: {
        fileTypeAccept() {
            return this.isImage
                ? 'image/png, image/jpeg, image/webp, image/jpg'
                : 'image/png, image/jpeg, image/webp, image/jpg, application/pdf , application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        },
    },
    data() {
        return {
            fileName: '',
            docLink: null,
            deleteFileDialog: false,
            isUploading: false,
            uploadDisabled: this.disabled ?? false,
        };
    },
    watch: {
        logoUrl() {
            this.docLink = this.logoUrl;
            if (this.docLink) {
                var splitLink = this.docLink.split('/');
                this.fileName = splitLink[splitLink.length - 1];
            }
        },
        delete() {
            if (this.delete) {
                this.cancel();
            }
        },
        disabled() {
            this.uploadDisabled = this.disabled;
        },
    },
    methods: {
        chooseLogo(event) {
            console.log('id', this.id);
            currentUser.refreshCognitoJwt();
            const file = event.target.files[0];
            const maxFileSize = 10485760; // 10 mb limit

            if (file) {
                if (file.size <= maxFileSize) {
                    const nameSplit = file.name.split('.');
                    const fileExtension = nameSplit[nameSplit.length - 1];
                    const isValidExtension = this.isImage ? ifImage(fileExtension) : ifDoc(fileExtension);

                    if (isValidExtension) {
                        this.create_blob(file).then((blob_string) => {
                            this.isUploading = true;
                            let paramKey;
                            getAxios()
                                .get(
                                    `${process.env.VUE_APP_COMMON_BASE_URL}v1/upload-url?id=${this.id}&content_type=${
                                        file.type
                                    }&file_name=${file.name.split('.')[0].substring(0, 70)}.${fileExtension}`,
                                    {
                                        headers: {
                                            Authorization: currentUser.jwt,
                                            'Content-Type': 'application/json',
                                        },
                                    }
                                )
                                .then((res) => {
                                    paramKey = res.data.Key;
                                    return axios.put(res.data.uploadURL, blob_string, {
                                        headers: {
                                            'Content-Type': file.type,
                                        },
                                    });
                                })
                                .then(() => {
                                    console.log(
                                        'ID',
                                        this.id,
                                        'FIELD NAME',
                                        this.fieldName,
                                        'PARAM KEY',
                                        paramKey,
                                        'CURRENT USER JWT',
                                        currentUser.jwt
                                    );
                                    return getAxios().post(
                                        `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v1/file-upload?id=${this.id}&param_key=${paramKey}&field_id=${this.fieldId}`,
                                        {
                                            headers: {
                                                Authorization: currentUser.jwt,
                                                'Content-Type': 'application/json',
                                            },
                                        }
                                    );
                                })
                                .then((res) => {
                                    this.isUploading = false;
                                    this.docLink = res.data.data.link;

                                    const splitLink = this.docLink.split('/');
                                    this.fileName = splitLink[splitLink.length - 1];
                                    this.$emit('saved', this.docLink, this.fileName);
                                    this.$toast.add({ severity: 'success', summary: 'File uploaded successfully', life: 3000 });
                                })
                                .catch((err) => {
                                    this.isUploading = false;
                                    event.target.value = '';
                                    this.$toast.add({
                                        severity: 'error',
                                        summary: 'Error while uploading file',
                                        detail: err.data,
                                        life: 3000,
                                    });
                                    console.error('Error', err.stack);
                                });
                        });
                    } else {
                        this.isUploading = false;
                        event.target.value = '';
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'file type not accepted', life: 3000 });
                    }
                } else {
                    this.isUploading = false;
                    event.target.value = '';
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'File size is large. Maximum size is 10MB',
                        life: 3000,
                    });
                }
            }
        },
        create_blob(file) {
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve(new Int8Array(reader.result));
                reader.onerror = (error) => reject(error);
            });
        },
        cancel() {
            this.deleteFileDialog = false;
            this.docLink = null;

            getAxios()
                .delete(`${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v1/file-upload?id=${this.id}&field_id=${this.fieldId}`, {
                    headers: {
                        Authorization: currentUser.jwt,
                    },
                })
                .then((res) => {
                    if (res.status == 200) {
                        this.$toast.add({ severity: 'success', summary: res.data.message, life: 3000 });
                        this.$emit('saved', null, null);
                    }
                })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: err.data, life: 3000 });
                });
        },
    },
};
</script>

<style scoped lang="scss">
.image-logo {
    width: initial;
    height: 100%;
    max-height: 40px;
    max-width: 100px;
    border-radius: 5px !important;
}

.file-container {
    width: 30rem;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn {
    border: 1.5px solid #949ba1;
    color: gray;
    background-color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
}

.upload-disabled {
    color: #b0b0b0 !important;
    cursor: not-allowed !important;
}

.upload-btn-wrapper input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    margin-left: -77px !important;
}

.cross {
    cursor: pointer !important;
    margin-left: 0.5rem;
}
.attach-link {
    position: relative;
    left: 1.5rem;
    top: 0.5rem;
    word-break: break-all;
    float: right;
    margin-bottom: 2rem;
    > a {
        color: blue;
    }
}
.upload-link {
    position: relative;
    left: 1.5rem;
    word-break: break-all;
    float: left;
    margin-bottom: 2rem;
    display: flex;
    > a {
        color: blue;
    }
}
.attach-file {
    color: #126a70;
    margin-left: 3rem;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
